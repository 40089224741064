import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'www',
    lng: window.Site?.targeting.section,
    resources: {
      www: {
        common: require('./locales/www/common.json'),
        authOverlay: require('./locales/www/auth-overlay.json'),
        profile: require('./locales/www/profile.json'),
        loginPage: require('./locales/www/login-page.json'),
        registrationPage: require('./locales/www/registration-page.json'),
        forms: require('./locales/www/forms.json'),
        mobileAuth: require('./locales/www/mobile-auth.json'),
        errors: require('./locales/www/errors.json'),
        bookmarks: require('./locales/www/bookmarks.json'),
        onboarding: require('./locales/www/onboarding.json')
      },
      kaz: {
        common: require('./locales/kaz/common.json'),
        authOverlay: require('./locales/kaz/auth-overlay.json'),
        profile: require('./locales/kaz/profile.json'),
        loginPage: require('./locales/kaz/login-page.json'),
        registrationPage: require('./locales/kaz/registration-page.json'),
        forms: require('./locales/kaz/forms.json'),
        mobileAuth: require('./locales/kaz/mobile-auth.json'),
        errors: require('./locales/kaz/errors.json'),
        bookmarks: require('./locales/kaz/bookmarks.json'),
        onboarding: require('./locales/kaz/onboarding.json')
      }
    },
    ns: [
      'common',
      'authOverlay',
      'profile',
      'loginPage',
      'registrationPage',
      'forms',
      'mobileAuth',
      'errors',
      'bookmarks',
      'onboarding'
    ],
    defaultNS: 'common'
  });

i18n.languages = ['www', 'kaz'];

export default i18n;
