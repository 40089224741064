import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, ICON_NAMES, Typography} from '@nur-portal/ui-kit';

import styles from './benefits.module.css';

const getBenefits = t => ([
  {
    iconName: ICON_NAMES.REACTION_20,
    text: t('benefits.benefit1')
  },
  {
    iconName: ICON_NAMES.COMMENT_DETAIL_20,
    text: t('benefits.benefit2')
  },
  {
    iconName: ICON_NAMES.NEWS_20,
    text: t('benefits.benefit3')
  }
]);

const Benefits = ({
  translationKey = 'loginPage'
}) => {
  const {t} = useTranslation(translationKey);
  const benefits = getBenefits(t);

  return (
    <div className={styles.root}>
      {benefits.map(benefit => (
        <div key={benefit.iconName} className={styles.row}>
          <Icon name={benefit.iconName} className={styles.icon} />
          <Typography variant="text-16">{benefit.text}</Typography>
        </div>
      ))}
    </div>
  );
};

export {Benefits};
