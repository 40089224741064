import React from 'react';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';

import {Tabs} from '@nur-portal/ui-kit';

import {createLinkToLogin, createLinkToRegistration, NAVIGATION_ROUTES} from '../../constants';

import styles from './navigation.module.css';

const Navigation = ({
  activeRoute
}) => {
  const {t} = useTranslation('loginPage');
  const query = queryString.parse(decodeURIComponent(location.search)) || {};

  const hasQuery = Boolean(Object.values(query).length);

  const tabList = [
    {
      id: NAVIGATION_ROUTES.LOGIN,
      text: t('navigation.login'),
      href: createLinkToLogin(query),
      isActive: activeRoute === NAVIGATION_ROUTES.LOGIN || activeRoute === NAVIGATION_ROUTES.MOBILE_LOGIN,
      ...hasQuery && {
        rel: 'nofollow'
      }
    },
    {
      id: NAVIGATION_ROUTES.REGISTRATION,
      text: t('navigation.registration'),
      href: createLinkToRegistration(query),
      isActive: activeRoute === NAVIGATION_ROUTES.REGISTRATION || activeRoute === NAVIGATION_ROUTES.MOBILE_AUTH,
      ...hasQuery && {
        rel: 'nofollow'
      }
    }
  ];

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Tabs
          tabList={tabList}
          className={styles.tabs}
        />
      </div>
    </div>
  );
};

export {Navigation};
