// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navigation-module__root--1InPn{margin:0 -20px 46px;border-bottom:1px solid #e2e2e2}@media screen and (min-width:1152px){.navigation-module__root--1InPn{width:100vw;margin-left:calc((-100vw + 1088px)/2)}}@media screen and (min-width:756px) and (max-width:1151px){.navigation-module__root--1InPn{margin-left:-45px;margin-right:-45px}}.navigation-module__wrapper--3_XWX{display:flex;justify-content:center}.navigation-module__tabs--3nq0M{border:none;width:100%;position:relative}.navigation-module__tabs--3nq0M:after,.navigation-module__tabs--3nq0M:before{content:\"\";position:absolute;top:0;bottom:0;width:1px;z-index:1;background-color:#e2e2e2}.navigation-module__tabs--3nq0M:before{left:0}.navigation-module__tabs--3nq0M:after{right:0}@media screen and (min-width:1152px){.navigation-module__tabs--3nq0M{width:660px}}", ""]);
// Exports
exports.locals = {
	"root": "navigation-module__root--1InPn",
	"wrapper": "navigation-module__wrapper--3_XWX",
	"tabs": "navigation-module__tabs--3nq0M"
};
module.exports = exports;
