import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';

import {Link, Sprite, Typography} from '@nur-portal/ui-kit';

import {initAppleIdAuth} from '../../../apple-id-auth';
import {AppleButton} from '../../../website-ui/components/apple-button';
import {APPLE_BUTTON_SOURCES} from '../../../website-ui/components/apple-button/constants';
import {GoogleButton} from '../../../website-ui/components/google-button';
import {GOOGLE_BUTTON_SOURCES} from '../../../website-ui/components/google-button/constants';
import {Benefits} from '../components/benefits';
import {Navigation} from '../components/navigation';
import {PhoneNumberAuth} from '../components/phone-number-auth';
import {NAVIGATION_ROUTES} from '../constants';

import {LOGIN_STEPS} from './constants';

import styles from './login.module.css';

const LoginPage = () => {
  const {t} = useTranslation('loginPage');

  const parsedSearch = queryString.parse(decodeURIComponent(location.search)) || {};

  const [phoneNumber, setPhoneNumber] = useState(parsedSearch.phoneNumber || '');

  const [step, setStep] = useState(parsedSearch.phoneNumber
    ? LOGIN_STEPS.PHONE
    : LOGIN_STEPS.DEFAULT
  );

  const initialPhoneNumber = parsedSearch.phoneNumber || phoneNumber || '';

  useEffect(() => {
    initAppleIdAuth();
  }, []);

  return (
    <div>
      <Navigation activeRoute={NAVIGATION_ROUTES.LOGIN} />
      <div className={styles.container}>
        <GoogleButton source={GOOGLE_BUTTON_SOURCES.LOGIN} />
        <div className={styles.appleButtonWrapper}>
          <AppleButton source={APPLE_BUTTON_SOURCES.LOGIN} />
        </div>
        <div className={styles.divider}>
          <Typography variant="text-14" className={styles.dividerText}>
            {t('dividerText')}
          </Typography>
        </div>
        <div className={styles.buttonWrapper}>
          <PhoneNumberAuth
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            initialPhoneNumber={initialPhoneNumber}
            step={step}
            setStep={setStep}
          />
        </div>
        <div className={styles.benefitRoot}>
          <Typography
            variantMapping={{
              desktop: 'title-21',
              mobile: 'title-18'
            }}
            className={styles.subHeader}
          >
            {t('questionText')}
            <Link
              href={`${location.origin}/${NAVIGATION_ROUTES.REGISTRATION}/`}
              className={styles.registerLink}
              rel="nofollow"
            >
              {t('registerLink')}
            </Link>
          </Typography>
          <Benefits translationKey="loginPage" />
        </div>
      </div>
      <Sprite />
    </div>
  );
};

export {LoginPage};
