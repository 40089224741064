import {NOTIFICATION_VARIANTS} from '@nur-portal/ui-kit';

import {handleLoginSuccess} from './modules/auth-buttons';
import {loadScript} from './services/helpers';
import hiveSdk from './services/hive-sdk';
import {getStorageUserId} from './services/user-id-helpers';
import {showNotification} from './website-ui/components/notification-center/helpers';
import {isCommonError} from './website-ui/helpers/common-error';
import i18n from './website-ui/i18n/config';

const APPLE_AUTH_SCRIPT_URL = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ru_RU/appleid.auth.js';

const appleAuthCallback = event => {
  const authResponse = event?.detail?.authorization || {};

  hiveSdk.signUpApple(
    {
      ...authResponse,
      idToken: authResponse.id_token,
      state: getStorageUserId()
    },
    response => {
      showNotification({
        variant: NOTIFICATION_VARIANTS.SUCCESS,
        text: i18n.t('loginPage:loginViaPhone.notifications.success')
      });

      handleLoginSuccess(response);
    },
    responseError => {
      if (isCommonError(responseError.code)) {
        showNotification({
          variant: NOTIFICATION_VARIANTS.ALERT,
          text: i18n.t(`profile.commonErrors.${responseError.code}`)
        });
      }
    },
    null
  );
};

const initAppleButton = () => {
  window.AppleID.auth && window.AppleID.auth.init({
    clientId: 'tutby.news.kazahstan',
    scope: 'name email',
    redirectURI: window.location.origin,
    usePopup: true
  });
};

function initAppleIdAuth (successCallback, source) {
  if (document.getElementById('AppleID')) {
    initAppleButton();
    return;
  }

  loadScript(
    'AppleID',
    APPLE_AUTH_SCRIPT_URL,
    () => {
      initAppleButton();

      document.addEventListener('AppleIDSignInOnSuccess', event => {
        successCallback ? successCallback(event, source) : appleAuthCallback(event, source);
      });

      document.addEventListener('AppleIDSignInOnFailure', event => {
        console.error(event);
      });
    }
  );
}

export {initAppleIdAuth};
