import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import * as zod from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {
  Button,
  BUTTON_VARIANTS,
  ButtonWithLoading,
  InputPassword,
  Link,
  NOTIFICATION_VARIANTS,
  Typography
} from '@nur-portal/ui-kit';

import hiveSDK from '../../../../../services/hive-sdk';
import {showNotification} from '../../../../../website-ui/components/notification-center/helpers';
import {isCommonError, UNHANDLED_ERROR_KEY} from '../../../../../website-ui/helpers/common-error';
import {maskPhoneNumber, unmaskPhoneNumber} from '../../../../../website-ui/helpers/phone-number';
import {publishErrorEvent} from '../../../../../website-ui/helpers/publish-error-event';
import {handleLoginSuccess} from '../../../../auth-buttons';
import {createLinkToRestore} from '../../../constants';
import {useAutoFocus} from '../../../hooks/useAutoFocus';

import styles from '../phone-number-auth.module.css';

const FIELD_NAME = 'password';
const MIN_PASSWORD_SYMBOLS = 2;

const Password = ({
  phoneNumber,
  goBack,
  onSuccess
}) => {
  const {t} = useTranslation('loginPage');
  const [isLoading, setIsLoading] = useState(false);

  const passwordSchema = zod.object({
    [FIELD_NAME]: zod
      .string()
      .min(MIN_PASSWORD_SYMBOLS, 'PROFILE_PASSWORD_TOO_SHORT')
  }).required();

  const {
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: {
      errors
    }
  } = useForm({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      [FIELD_NAME]: ''
    }
  });

  const errorText = errors[FIELD_NAME]
    ? t(`loginViaPhone.stepPassword.errors.${errors[FIELD_NAME].message}`, t(UNHANDLED_ERROR_KEY))
    : '';

  const inputRef = useAutoFocus();

  const phoneValue = watch(FIELD_NAME);
  const query = queryString.parse(location.search) || {};

  const restoreRouteUrl = createLinkToRestore({
    ...query,
    phoneNumber: phoneNumber.replace(/\s/g, '')
  });

  const formSubmitCallback = ({password}) => {
    setIsLoading(true);
    hiveSDK.login(
      {
        phone: unmaskPhoneNumber(phoneNumber),
        password
      },
      response => {
        showNotification({
          variant: NOTIFICATION_VARIANTS.SUCCESS,
          text: t('loginViaPhone.notifications.success')
        });
        onSuccess(response);
        handleLoginSuccess(response);
        setIsLoading(false);
      },
      responseError => {
        setIsLoading(false);

        if (isCommonError(responseError.code)) {
          showNotification({
            variant: NOTIFICATION_VARIANTS.ALERT,
            text: t(`common:profile.commonErrors.${responseError.code}`)
          });
        } else {
          setError(FIELD_NAME, responseError);
          publishErrorEvent(responseError.message);
        }
      },
      null,
      true
    );
  };

  return (
    <form onSubmit={handleSubmit(formSubmitCallback)}>
      <Typography variant="title-16">
        {t('loginViaPhone.stepPassword.labelText')}
      </Typography>
      <div className={styles.labelSub}>
        <Typography variant="text-16" >
          {t('loginViaPhone.stepPassword.subLabelText', {phoneNumber: maskPhoneNumber(phoneNumber)})}
        </Typography>
        <Link
          href={restoreRouteUrl}
          rel="nofollow"
        >
          <Typography variant="text-14" >
            {t('loginViaPhone.stepPassword.forgotPassword')}
          </Typography>
        </Link>
      </div>
      <InputPassword
        labelText=""
        ref={inputRef}
        placeholder="Введите пароль"
        errorText={errorText}
        errorClassName={styles.inputError}
        value={phoneValue}
        onChange={event => {
          setValue(FIELD_NAME, event.target.value);
          clearErrors(FIELD_NAME);
        }}
      />
      <div className={styles.actionButtons}>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          className={styles.actionButton}
          onClick={goBack}
          type="button"
        >
          {t('loginViaPhone.stepPassword.buttonBack')}
        </Button>
        <ButtonWithLoading
          className={styles.actionButton}
          isLoading={isLoading}
          type="submit"
        >
          {isLoading
            ? t('loginViaPhone.stepPassword.buttonLoading')
            : t('loginViaPhone.stepPassword.buttonNext')
          }
        </ButtonWithLoading>
      </div>
    </form>
  );
};

export {Password};
