// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".login-module__container--21Qtw{max-width:660px;margin:0 auto}.login-module__appleButtonWrapper--2p1dy{margin-top:15px}.login-module__divider--3y_3M{margin:25px auto;display:flex;width:100%;max-width:370px;justify-content:center;position:relative}.login-module__divider--3y_3M:before{content:\"\";position:absolute;left:0;right:0;top:10px;height:1px;background-color:#e2e2e2}.login-module__registerLink--2Uf3W{color:#1e54b7;border-bottom:2px dotted #1e54b7}.login-module__dividerText--1lccC{padding:0 10px;background:#fff;display:flex;color:#757575;z-index:1}.login-module__subHeader--10ger{margin-bottom:25px}.login-module__benefitRoot--FwmQ-{margin-top:50px;padding:20px;background-color:#eff3fe;border-radius:10px}@media screen and (min-width:1152px){.login-module__benefitRoot--FwmQ-{padding:30px}}.login-module__buttonWrapper--2kEB-{max-width:370px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"container": "login-module__container--21Qtw",
	"appleButtonWrapper": "login-module__appleButtonWrapper--2p1dy",
	"divider": "login-module__divider--3y_3M",
	"registerLink": "login-module__registerLink--2Uf3W",
	"dividerText": "login-module__dividerText--1lccC",
	"subHeader": "login-module__subHeader--10ger",
	"benefitRoot": "login-module__benefitRoot--FwmQ-",
	"buttonWrapper": "login-module__buttonWrapper--2kEB-"
};
module.exports = exports;
