const GOOGLE_BUTTON_SOURCES = {
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  REACTIONS_MODAL_ARTICLE: 'REACTIONS_MODAL_ARTICLE',
  REACTIONS_MODAL_COMMENT: 'REACTIONS_MODAL_COMMENT'
};

export {
  GOOGLE_BUTTON_SOURCES
};
