import {publishEvent} from '../../services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from '../../services/analytics/pub-sub/constants';

const COMMON_ERROR_CODES = {
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR'
  // UNAUTHORIZED: 'Unauthorized'
};

const COMMON_ERROR_EVENTS = {
  [COMMON_ERROR_CODES.RATE_LIMIT_EXCEEDED]: ANALYTIC_EVENTS.RATE_LIMIT_EXCEEDED_ERROR,
  [COMMON_ERROR_CODES.INTERNAL_SERVER_ERROR]: ANALYTIC_EVENTS.INTERNAL_SERVER_ERROR
};

const COMMON_ERRORS_TARGET_VALUES = {
  [COMMON_ERROR_CODES.RATE_LIMIT_EXCEEDED]: 'Request limit exceeded, try again in a minute',
  [COMMON_ERROR_CODES.INTERNAL_SERVER_ERROR]: 'Technical error, try again later'
};

const UNHANDLED_ERROR_KEY = 'common:profile.commonErrors.INTERNAL_SERVER_ERROR';

const isCommonError = errorCode => Object.values(COMMON_ERROR_CODES).includes(errorCode);

const publishCommonError = (errorCode, data = {}) => {
  if (!errorCode || !COMMON_ERROR_EVENTS[errorCode]) {
    return;
  }

  const errorEventName = COMMON_ERROR_EVENTS[errorCode];

  if (errorEventName) {
    publishEvent(errorEventName, {
      ...data,
      event_target_value: COMMON_ERRORS_TARGET_VALUES[errorCode]
    });
  }
};

export {
  isCommonError,
  publishCommonError,
  COMMON_ERROR_CODES,
  UNHANDLED_ERROR_KEY
};
