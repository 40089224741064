import {useEffect, useRef} from 'react';

const useAutoFocus = () => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return inputRef;
};

export {useAutoFocus};
