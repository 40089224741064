import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';

import {GOOGLE_CLIENT_INIT_EVENT_NAME} from '../../../google-auth';

import {GOOGLE_BUTTON_SOURCES} from './constants';

import styles from './google-button.module.css';

const locale = window.Site?.targeting?.section === 'kaz' ? 'kk' : 'ru';
const GOOGLE_BUTTON_MAX_WIDTH = 370;
const DEFAULT_PADDINGS = 40;

const GOOGLE_BUTTON_DEFAULT_WIDTH = document.body.clientWidth >= GOOGLE_BUTTON_MAX_WIDTH + DEFAULT_PADDINGS
  ? GOOGLE_BUTTON_MAX_WIDTH
  : document.body.clientWidth - DEFAULT_PADDINGS;

const GoogleButton = ({
  source,
  buttonWidth = GOOGLE_BUTTON_DEFAULT_WIDTH,
  onClick,
  className
}) => {
  const [isGoogleInit, setIsGoogleInit] = useState(false);

  const googleButtonRef = useRef();

  useEffect(() => {
    window.addEventListener(GOOGLE_CLIENT_INIT_EVENT_NAME, () => {
      setIsGoogleInit(true);
    });
  }, []);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.renderButton(googleButtonRef.current, {
        size: 'large',
        theme: 'filled_blue',
        text: source === GOOGLE_BUTTON_SOURCES.REGISTRATION ? 'signup_with' : 'continue_with',
        width: String(buttonWidth),
        locale,
        click_listener: onClick
      });
    }
  }, [isGoogleInit, source]);

  return (
    <div
      ref={googleButtonRef}
      className={clsx(
        styles.googleButton,
        className
      )}
    />
  );
};

export {GoogleButton};
