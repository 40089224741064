import {publishEvent} from '../../services/analytics/pub-sub';

// NOTE: errorMapper should be key-value {[responseError.message]: PUB_SUB_EVENT_NAME}

const publishErrorEvent = (responseErrorMessage, errorMapper) => {
  if (!responseErrorMessage || !errorMapper) {
    return;
  }
  const errorEventName = errorMapper[responseErrorMessage];

  if (errorEventName) {
    publishEvent(errorEventName);
  }
};

export {publishErrorEvent};
