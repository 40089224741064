import {getMaskValue} from '@nur-portal/ui-kit';

const PHONE_NUMBER_DIGITS = 10;

const PHONE_NUMBER_MASK = 'XXX XXX XX XX';

const PHONE_NUMBER_PREFIX_MASK = '+7';

// Seconds*
const DEFAULT_CODE_TTL = 300;
const CODE_DIGITS = 4;

const preparePhoneNumber = phoneNumber => (phoneNumber ? phoneNumber.slice(-PHONE_NUMBER_DIGITS) : '');

const maskPhoneNumber = phoneNumber => (phoneNumber
  ? `${PHONE_NUMBER_PREFIX_MASK} ${getMaskValue(phoneNumber, PHONE_NUMBER_MASK)}`
  : '');

const unmaskPhoneNumber = phoneNumber => `+7${phoneNumber.replace(/\s/g, '')}`;

export {
  preparePhoneNumber,
  maskPhoneNumber,
  unmaskPhoneNumber,
  CODE_DIGITS,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PREFIX_MASK,
  DEFAULT_CODE_TTL
};
