// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".google-button-module__googleButton--1qRm6{display:flex;justify-content:center;height:40px}", ""]);
// Exports
exports.locals = {
	"googleButton": "google-button-module__googleButton--1qRm6"
};
module.exports = exports;
