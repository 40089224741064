import {NOTIFICATION_VARIANTS} from '@nur-portal/ui-kit';

import {GOOGLE_AUTH_SOURCE_STORAGE_NAME} from './constants/google-auth';
import {FEATURE_NAMES} from './growthbook/constants';
import {handleLoginSuccess} from './modules/auth-buttons';
import {loadScript} from './services/helpers';
import hiveSdk from './services/hive-sdk';
import {getStorageUserId} from './services/user-id-helpers';
import {showNotification} from './website-ui/components/notification-center/helpers';
import {isCommonError} from './website-ui/helpers/common-error';
import i18n from './website-ui/i18n/config.js';

const locale = window.Site?.targeting?.section === 'kaz' ? 'kk' : 'ru';

const GOOGLE_CLIENT_INIT_EVENT_NAME = 'GOOGLE_CLIENT_INIT';

const defaultSuccessCallback = data => {
  hiveSdk.signUpGoogle(
    {
      ...data,
      state: getStorageUserId()
    },
    async response => {
      await handleLoginSuccess(response);
      showNotification({
        variant: NOTIFICATION_VARIANTS.SUCCESS,
        text: i18n.t('loginPage:loginViaPhone.notifications.success')
      });
    },
    responseError => {
      if (isCommonError(responseError.code)) {
        showNotification({
          variant: NOTIFICATION_VARIANTS.ALERT,
          text: i18n.t(`profile.commonErrors.${responseError.code}`)
        });
      }
    },
    null
  );
};

const initGoogleAuth = (
  successCallback = defaultSuccessCallback,
  shouldRenderOneTap = true
) => {
  const googleClientId = window?.Site?.googleClientId;

  const isOneTapFeatureEnabled = window.growthBook?.getFeatureValue(FEATURE_NAMES.ONE_TAP);

  loadScript(
    'oauth',
      `https://accounts.google.com/gsi/client?hl=${locale}`,
      () => {
        if (window.google && window.google.accounts) {
          window.google.accounts.id.initialize({
            client_id: googleClientId,
            callback: successCallback,
            use_fedcm_for_prompt: true,
            cancel_on_tap_outside: false,
            itp_support: true,
            context: 'continue',
            ux_mode: 'popup',
            auto_select: false
          });
          window.dispatchEvent(new CustomEvent(GOOGLE_CLIENT_INIT_EVENT_NAME));
          localStorage.removeItem(GOOGLE_AUTH_SOURCE_STORAGE_NAME);
          if (shouldRenderOneTap && isOneTapFeatureEnabled) {
            window.google.accounts.id.prompt();
          }
        }
      },
      null);
};

export {
  GOOGLE_CLIENT_INIT_EVENT_NAME,
  initGoogleAuth
};
