// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".apple-button-module__button--1Lt4R{cursor:pointer;width:100%;flex-shrink:0;display:flex;justify-content:center}", ""]);
// Exports
exports.locals = {
	"button": "apple-button-module__button--1Lt4R"
};
module.exports = exports;
