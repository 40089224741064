import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as zod from 'zod';

import {zodResolver} from '@hookform/resolvers/zod';
import {ButtonWithLoading, getMaskValue, InputPhone, NOTIFICATION_VARIANTS, Typography} from '@nur-portal/ui-kit';

import hiveSDK from '../../../../../services/hive-sdk';
import {getAccessToken} from '../../../../../services/tokens';
import {showNotification} from '../../../../../website-ui/components/notification-center/helpers';
import {isCommonError, UNHANDLED_ERROR_KEY} from '../../../../../website-ui/helpers/common-error';
import {
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PREFIX_MASK,
  unmaskPhoneNumber
} from '../../../../../website-ui/helpers/phone-number';
import {publishErrorEvent} from '../../../../../website-ui/helpers/publish-error-event';
import {useAutoFocus} from '../../../hooks/useAutoFocus';

import styles from '../phone-number-auth.module.css';

const FIELD_NAME = 'phone';
const PHONE_DIGITS = 13; // NOTE spaces included

const PhoneNumber = ({
  setPhoneNumber,
  initialPhoneNumber,
  goNext
}) => {
  const {t} = useTranslation('loginPage');
  const [isLoading, setIsLoading] = useState(false);

  const phoneNumberSchema = zod.object({
    [FIELD_NAME]: zod
      .string()
      .min(PHONE_DIGITS, 'PROFILE_PHONE_TOO_SHORT')
  }).required();

  const {
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    setError,
    formState: {
      errors
    }
  } = useForm({
    resolver: zodResolver(phoneNumberSchema),
    defaultValues: {
      [FIELD_NAME]: getMaskValue(initialPhoneNumber, PHONE_NUMBER_MASK)
    }
  });

  const inputRef = useAutoFocus();
  const phoneValue = watch(FIELD_NAME);

  const errorText = errors[FIELD_NAME]
    ? t(`loginViaPhone.stepPhone.errors.${errors[FIELD_NAME].message}`, t(UNHANDLED_ERROR_KEY))
    : '';

  const formSubmitCallback = ({phone}) => {
    setIsLoading(true);
    hiveSDK.loginValidate(
      {
        phone: unmaskPhoneNumber(phone)
      },
      () => {
        setIsLoading(false);
        setPhoneNumber(phone);
        goNext();
      },
      responseError => {
        setIsLoading(false);
        if (isCommonError(responseError.code)) {
          showNotification({
            variant: NOTIFICATION_VARIANTS.ALERT,
            text: t(`profile.commonErrors.${responseError.code}`)
          });
        } else {
          setError(FIELD_NAME, responseError);
          publishErrorEvent(responseError.message);
        }
      },
      getAccessToken(),
      false
    );
  };

  return (
    <form onSubmit={handleSubmit(formSubmitCallback)}>
      <Typography variant="title-16" className={styles.labelPhone}>
        {t('loginViaPhone.stepPhone.labelText')}
      </Typography>
      <InputPhone
        ref={inputRef}
        prefixMask={PHONE_NUMBER_PREFIX_MASK}
        errorText={errorText}
        errorClassName={styles.inputError}
        value={phoneValue}
        type="tel"
        inputMode="tel"
        onChange={event => {
          setValue(FIELD_NAME, event.target.value);
          clearErrors(FIELD_NAME);
        }}
      />
      <ButtonWithLoading
        isLoading={isLoading}
        type="submit"
        className={styles.submitButton}
      >
        {t('loginViaPhone.stepPhone.buttonText')}
      </ButtonWithLoading>
    </form>
  );
};

export {PhoneNumber};
