// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".phone-number-auth-module__defaultButton--3oTU_{width:100%}.phone-number-auth-module__buttonRoot--STKYq{margin:0 auto;max-width:400px}.phone-number-auth-module__inputError--lvBsQ{position:relative;padding-top:5px}.phone-number-auth-module__submitButton--2cTDO{width:100%;margin-top:20px}.phone-number-auth-module__labelPhone--1kRI8{margin-bottom:10px}.phone-number-auth-module__actionButtons--q0w78{display:flex;gap:10px;margin-top:20px}@media screen and (max-width:517px){.phone-number-auth-module__actionButtons--q0w78{flex-direction:column-reverse}}.phone-number-auth-module__actionButton--6J6Gx{width:100%}.phone-number-auth-module__labelSub--iKPTt{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px;margin-top:5px}@media screen and (max-width:517px){.phone-number-auth-module__labelSub--iKPTt{flex-direction:column;align-items:flex-start;gap:15px;margin-bottom:20px}}", ""]);
// Exports
exports.locals = {
	"defaultButton": "phone-number-auth-module__defaultButton--3oTU_",
	"buttonRoot": "phone-number-auth-module__buttonRoot--STKYq",
	"inputError": "phone-number-auth-module__inputError--lvBsQ",
	"submitButton": "phone-number-auth-module__submitButton--2cTDO",
	"labelPhone": "phone-number-auth-module__labelPhone--1kRI8",
	"actionButtons": "phone-number-auth-module__actionButtons--q0w78",
	"actionButton": "phone-number-auth-module__actionButton--6J6Gx",
	"labelSub": "phone-number-auth-module__labelSub--iKPTt"
};
module.exports = exports;
