// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".benefits-module__root--qL_Kw{flex-direction:column}.benefits-module__root--qL_Kw,.benefits-module__row--r3gso{display:flex;gap:15px}.benefits-module__row--r3gso{width:100%;align-items:center}.benefits-module__icon--2eQmk{color:#1e54b7;width:20px;height:20px;flex-shrink:0}", ""]);
// Exports
exports.locals = {
	"root": "benefits-module__root--qL_Kw",
	"row": "benefits-module__row--r3gso",
	"icon": "benefits-module__icon--2eQmk"
};
module.exports = exports;
