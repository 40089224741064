import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, BUTTON_VARIANTS} from '@nur-portal/ui-kit';

import {LOGIN_STEPS} from '../../login/constants';

import {Password} from './password';
import {PhoneNumber} from './phone-number';

import styles from './phone-number-auth.module.css';

const PhoneNumberAuth = ({
  phoneNumber,
  initialPhoneNumber,
  setPhoneNumber,
  step,
  setStep,
  onSuccess = () => {}
}) => {
  const {t} = useTranslation('loginPage');

  return (
    <div className={styles.buttonRoot}>
      {step === LOGIN_STEPS.DEFAULT && (
        <Button
          className={styles.defaultButton}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() => setStep(LOGIN_STEPS.PHONE)}
        >
          {t('loginViaPhone.buttonText')}
        </Button>
      )}
      {step === LOGIN_STEPS.PHONE && (
        <PhoneNumber
          initialPhoneNumber={initialPhoneNumber}
          setPhoneNumber={setPhoneNumber}
          goNext={() => setStep(LOGIN_STEPS.PASSWORD)}
        />
      )}
      {step === LOGIN_STEPS.PASSWORD && (
        <Password
          phoneNumber={phoneNumber}
          goBack={() => setStep(LOGIN_STEPS.PHONE)}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export {PhoneNumberAuth};
