const GOOGLE_AUTH_SOURCES = {
  ARTICLE_REACTIONS: 'ARTICLE_REACTIONS',
  COMMENT_REACTIONS: 'COMMENT_REACTIONS'
};

const GOOGLE_AUTH_SOURCE_STORAGE_NAME = 'GOOGLE_AUTH_SOURCE';

export {
  GOOGLE_AUTH_SOURCES,
  GOOGLE_AUTH_SOURCE_STORAGE_NAME
};
