import React from 'react';
import {render} from 'react-dom';

import {LoginPage} from '../modules/auth-pages/login';
import '../website-ui/i18n/config';

const rootElement = document.getElementById('js-login-page-container');

if (rootElement) {
  render(<LoginPage />, rootElement);
}

