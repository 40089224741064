import React from 'react';

import styles from './apple-button.module.css';

const DEFAULT_APPLE_BUTTON_PROPS = {
  mode: 'center-align',
  type: 'continue',
  color: 'black',
  border: false,
  borderRadius: 8,
  width: 370,
  height: 40
};

const AppleButton = ({
  onClick = () => {},
  ...props
}) => {
  const buttonProps = {
    ...DEFAULT_APPLE_BUTTON_PROPS,
    ...props
  };

  return (
    <div
      id="appleid-signin"
      data-mode={buttonProps.mode}
      data-type={buttonProps.type}
      data-color={buttonProps.color}
      data-border={buttonProps.border}
      data-border-radius={buttonProps.borderRadius}
      data-width={buttonProps.width}
      data-height={buttonProps.height}
      className={styles.button}
      onClick={onClick}
    />
  );
};

export {AppleButton};
